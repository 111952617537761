<template>
  <transition name="dialog-fade">
    <div v-if="show" ref="visual-dialog" class="visual-dialog-model">
      <div class="visual-dialog-wrap" :style="cp_style">
        <div class="visual-dialog-content">
          <slot></slot>
        </div>
        <div class="closed" @click="fn_close">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'VisualDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: '45%'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    top: {
      type: [Number, String],
      default: '20vh'
    }
  },
  data() {
    return {}
  },
  computed: {
    cp_style() {
      const width =
          typeof this.width === 'string' ? this.width : this.width + 'px'
      const height =
          typeof this.height === 'string' ? this.height : this.height + 'px'
      const top = typeof this.top === 'string' ? this.top : this.top + 'px'
      return {
        width,
        height,
        top
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.fn_open()
      }
    }
  },
  mounted() {
    this.fn_open()
  },
  methods: {
    fn_open() {
      this.fn_append__toBody()
    },
    fn_close() {
      this.$emit('update:show', false)
    },
    fn_append__toBody() {
      setTimeout(
        () =>
          this.$refs['visual-dialog'] &&
          document.body.appendChild(this.$refs['visual-dialog']),
        0
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.visual-dialog-model {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(12, 14, 22, 0.6);
}
.visual-dialog-model .visual-panel {
  background: linear-gradient(
    0deg,
    #3e4b59 0%,
    #32373d 5%,
    #292929 10%,
    #292929 90%,
    #32373d 95%,
    #3e4b59 100%
  );
}
.visual-dialog-wrap {
  @include position-middle;
  position: fixed;
}
.closed{
  position: fixed;
  top: 18vh;
  left: 75%;
  color: #fff;
  cursor: pointer;
  font-size: 26px;
}
.visual-dialog-content {
  max-height: 520px;
  padding: 15px 0;
}
</style>
